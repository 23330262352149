<template>
    <v-row  style="margin-left:3%; margin-top:20px; padding-right: 15px; width: 95%;">
        Productos 
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->
        
        <v-tooltip text="Actualizar" location="bottom" >
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="deep-orange-accent-3" @click="getdatos" ></v-btn>
            </template>
        </v-tooltip>
        <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3"  style="margin-left:5px; margin-bottom:20px " @click="newproduct();product.dialog = true; obtenermateriales();" >Nuevo producto</v-btn>
    </v-row>


<v-card>
    <template v-slot:text>
        <v-text-field
            height="20px"
            v-model="search"
            label="Search"
            single-line
            variant="outlined"
            hide-details
            density="compact"
            style="width: 45%;"
        ></v-text-field>
    </template>

        <v-data-table :loading="loadingTable"
        :headers="product.headers"
        :items="product.data"
        :search="search"
        density="compact"
        class="tabledts"
        >

        <template v-slot:[`item.Dimensiones`]="{ item }">
            {{ item.Medidad_ext_large }}Mts X   {{ item.Medidad_ext_ancho }} Mts X   {{ item.Medidad_ext_alto }}Mts
        </template>

        <template v-slot:[`item.Descripcion_corta`]="{ item }">
            <v-btn  density="compact" variant="text"
            @click="update(item); console.log(item) ">{{ item.Descripcion_corta }}</v-btn>
        </template>

        <template v-slot:[`item.show`]="{ item }">
            <v-btn icon="mdi-eye-outline" color="blue"  density="compact"
            @click="product.pdf = true; product.id = item.id; product.Descripcion_corta = item.Descripcion_corta "></v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
            <v-btn icon="mdi-delete" color="red"  density="compact"
            @click="dialog.warning = true; product.id = item.id; 
            dialog.message='Eliminar catalogo'+item.Descripcion_corta "></v-btn>
        </template>

        </v-data-table>
    </v-card>


    <v-dialog v-model="product.pdf" persistent  width="1024" >
    
        <v-card>
            <v-card-title>
                <span class="text-h5"> {{product.Descripcion_corta}} </span>
            </v-card-title>
        <v-card-text style="width: 100%; height: 600px;">
            <iframe style="width: 100%; height: 100%;" :src="urldat+'/producto/pdf/'+product.id" frameborder="0"></iframe>
        </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn  color="blue-darken-1" variant="text"
                    @click="product.pdf = false">
                    Cerrar
                </v-btn>                      
            </v-card-actions>
        </v-card>
    </v-dialog>

 
    <v-dialog v-model="product.dialog" persistent  width="1024" >
        <v-card>
        <v-card-title>
            <span class="text-h5">Clientes </span>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent  >
                <v-stepper :items=" ['Producto', 'Medidas', 'Generales']">
                    <template v-slot:[`item.1`]> 
                        <v-card title="Producto" flat>Establecer identificacion y descripcion general</v-card>
                        <v-row>
                        <v-col cols="12" sm="6"  md="3">
                                <v-autocomplete
                                label="Tipo"                       
                                v-model="product.typo"
                                :items="product.tipos"
                                item-title="Descripcion"
                                item-value="Codigo"
                                ></v-autocomplete>
                            </v-col> 
                        <v-col cols="12" sm="12"  md="9">
                            <v-text-field  v-model="product.Descripcion_corta" :rules="rules"  label="Nombre"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12"  md="12">
                            <v-textarea  v-model="product.Descripcio_largan"  label="Descripcion larga"></v-textarea>
                        </v-col>
                    </v-row>
                    </template>

                    <template  v-slot:[`item.2`]>
                        <v-card title="Medidas" flat>...</v-card>
                        <v-row>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_large"   label="Largo"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_ancho"  label="Ancho"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_alto"  label="Alto"></v-text-field >
                            </v-col>
                        </v-row>
                    </template>

                    <template v-slot:[`item.3`]>
                        <v-card title="Step Three" flat>...</v-card>
                        <v-row>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field  v-model="product.Estructura" label="Estructura"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field  v-model="product.Forro_ext" label="Forro exterior"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field  v-model="product.Forro_int" label="Forro interior"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field v-model="product.Piso" label="piso"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field v-model="product.Puertas" label="puertas"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field v-model="product.Instalacion" label="Instalacion"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field v-model="product.Rampa_hidraulica"  label="Rampa hidraulica"></v-text-field >
                            </v-col>
                        </v-row>
                    </template>

                    
                </v-stepper>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="blue-darken-1" variant="text"
                        @click="product.dialog = false">
                        Cerrar
                    </v-btn>

                    <v-btn  color="blue-darken-1" variant="text"
                        @click="guardar">
                        Guardar
                    </v-btn>                        
                </v-card-actions>
            </v-form>
        </v-card-text>
        
        </v-card>
    </v-dialog>




    <v-dialog v-model="product.dialogedit" fullscreen   >
        <v-card>
            <v-toolbar  dark color="primary" >
                <v-btn icon  dark  @click="product.dialogedit = false;getdatos()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>{{product.Descripcion_corta}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn
                    variant="text"
                    @click="product.action='update'; guardar()"
                    >
                    Guardar
                    </v-btn>
                </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-tabs v-model="tab">
                <v-tab value="GN">General</v-tab>
                <v-tab value="DT">Detalles</v-tab>
                <v-tab value="IMGs">Imagenes</v-tab>
            </v-tabs>
            <v-form @submit.prevent  >
                <v-window v-model="tab">
                        <v-window-item value="GN">
                            <br>
                            <v-row>
                            <v-col cols="12" sm="6"  md="3">
                                <v-autocomplete
                                label="Tipo"                       
                                v-model="product.typo"
                                :items="product.tipos"
                                item-title="Descripcion"
                                item-value="Codigo"
                                ></v-autocomplete>
                            </v-col> 
                            <v-col cols="12" sm="12"  md="3">
                            <v-text-field  v-model="product.Descripcion_corta" :rules="rules"  label="Nombre"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12"  md="6">
                                <v-textarea  v-model="product.Descripcio_largan"  label="Descripcion larga"></v-textarea>
                            </v-col>
                          
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_large"   label="Largo"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_ancho"  label="Ancho"></v-text-field >
                            </v-col>
                            <v-col cols="12" sm="12"  md="4">
                                <v-text-field type="number" v-model="product.Medidad_ext_alto"  label="Alto"></v-text-field >
                            </v-col>
                        </v-row>
                        </v-window-item>
                        <v-window-item value="DT">
                            <v-row>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field  v-model="product.Estructura" label="Estructura"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field  v-model="product.Forro_ext" label="Forro exterios"></v-text-field >
                                    </v-col>  
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field  v-model="product.Forro_int" label="Forro interior"></v-text-field >
                                    </v-col>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field v-model="product.Piso" label="piso"></v-text-field >
                                    </v-col>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field v-model="product.Puertas" label="puertas"></v-text-field >
                                    </v-col>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field v-model="product.Instalacion" label="Instalacion"></v-text-field >
                                    </v-col>
                                    <v-col cols="12" sm="12"  md="4">
                                        <v-text-field v-model="product.Rampa_hidraulica"  label="Rampa hidraulica"></v-text-field >
                                    </v-col>
                                </v-row>
                        </v-window-item>
                        <v-window-item value="IMGs">
                            <v-row>
                                <v-col cols="12" sm="12"  md="5">
                                    <v-file-input
                                        label="Imagen 1"
                                                                            
                                        id="img1"
                                        variant="filled"
                                        prepend-icon="mdi-camera"
                                    ></v-file-input>
                                </v-col>

                                <v-col cols="12" sm="12"  md="5">
                                    <v-file-input
                                        label="Imagen 2"
                                        id="img2"
                                        variant="filled"
                                        prepend-icon="mdi-camera"
                                    ></v-file-input>
                                </v-col>

                                <v-col cols="12" sm="12"  md="2">
                                    <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3" @click="guardarImages" >Guardar imagenes</v-btn>
                                </v-col>
                            </v-row>
                            <v-row style="width: 90%; margin-left: 5%;"> 
                                <img  style="width: 40%; height: 250px; margin-right: 5%; margin-left: 5%;" :src="product.img1" alt="">
                                <img  style="width: 40%;   height: 250px; " :src="product.img2" alt="">

                            </v-row>
                        </v-window-item>
                </v-window>
                Materiales
                <v-divider></v-divider> <br>  
                <v-row>
                    <v-col cols="12" sm="6"  md="6">
                        <v-autocomplete
                        label="Material"                       
                        v-model="materiales.materiale_id"
                        :items="materiales.datas"
                        item-title="Descripcion"
                        item-value="id"
                        ></v-autocomplete>
                    </v-col>    
                    <v-col cols="12" sm="6"  md="4">
                        <v-text-field  v-model="materiales.cantidad" type="number" label="Cantidad"></v-text-field >
                    </v-col> 
                    <v-col cols="12" sm="6"  md="2">
                        <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3" @click="newMaterial" >Add</v-btn>
                    </v-col> 
  
                    <v-data-table :loading="loadingTable"
                    :headers="materiales.headers"
                    :items="materiales.items"
                    density="compact"
                    class="tabledts"
                    >

                    <template v-slot:[`item.Descripcion`]="{ item }">
                        <v-btn  density="compact" variant="text"
                        @click="updateMaterial(item) ">{{ item.Descripcion }}</v-btn>
                    </template>

                    <template v-slot:[`item.Costo_Unitario`]="{ item }">
                        {{ item.Costo_personal == null ? item.Costo_Unitario : item.Costo_personal }}
                    </template>

                    <template v-slot:[`item.delete`]="{ item }">
                        <v-btn icon="mdi-delete" color="red"  density="compact"
                        @click="dialog.warning = true; materiales.id = item.id; 
                        dialog.message='Eliminar material '+item.Descripcion ; dialog.tipo='material' "></v-btn>
                    </template>

                    <template v-slot:[`item.total`]="{ item }">
                       {{ item.Costo_personal == null ? item.Costo_Unitario * item.Cantidad : item.Costo_personal  * item.Cantidad}}
                    </template>

                    
                    </v-data-table>
                </v-row>

            </v-form>
        </v-card-text>
        
        </v-card>
    </v-dialog>


    <v-dialog v-model="materiales.dialog"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="primary" title="Actualizar cantidad"></v-toolbar>
                <v-card-text>
                    <v-text-field v-if="materiales.family == 'MN'" type="number" v-model="this.materiales.costo"  label="Costo"></v-text-field >
                    <v-text-field type="number" v-model="materiales.cantidad"  label="Cantidad"></v-text-field >
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="materiales.dialog= false "
                >Cancelar</v-btn>
                <v-btn @click="guardarmateriales" >Actualizar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
 

    <v-dialog v-model="dialog.warning"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.warning = false "
                >Cancelar</v-btn>
                <v-btn v-if="dialog.tipo == 'material'"  @click="deleteMaterial " >Confirmar</v-btn>
                <v-btn v-else  @click="deleteproduct " >Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-snackbar  v-model="dialog.confirmacion"
        :timeout="2000"
        color="deep-purple-accent-4"
        elevation="24"
        >

        <strong>{{ dialog.message }}</strong>
    </v-snackbar>


    <v-dialog v-model="dialog.error"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.error = false "
                >Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15" color="primary" indeterminate ></v-progress-circular></v-dialog>


</template>

<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
    data () {
        return {
            urldat: url,
            tab: "GN",
            search: '',
            urlimages: process.env.VUE_APP_SERVICE_URL_IMGES,
            loadingTable:false,
            dialog: {
                loading:false,
                error: false,
                warning: false,
                confirmacion:false,
                message: '',
                tipo: ''
            },
            product: {
                pdf: false,
                dialogedit:false,
                dialog:false,
                marialles:[],
                headers: [
                    { key: 'Descripcion_corta', title: 'Producto' ,align: 'center'},
                    { key: 'Dimensiones', title: 'Dimensiones' ,align: 'center'},
                    { key: 'materiales', title: 'Materiales' ,align: 'center'},
                    { key: 'Precio', title: 'Costo' ,align: 'center'},
                    { key: 'show', title: 'Ver' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: [],
                tipos: [],
                typo: '',
                id: 0,
                Descripcion_corta: '',
                Descripcio_largan: '',
                Medidad_ext_large: 0.0,
                Medidad_ext_ancho: 0.0,
                Medidad_ext_alto: 0.0,
                Estructura: '',
                Forro_ext: '',
                Forro_int: '',
                Puertas: '',
                Piso: '',
                Instalacion: '',
                Rampa_hidraulica: '',
                Precio: 0.0,
                material: '',
                action: '',
                img2: "",
                img1: ""
            },
            materiales: {
                dialog:false,
                datas: [],
                id: 0,
                producto_id: 0,
                materiale_id: '',
                cantidad: 0,
                headers: [
                    { key: 'Descripcion', title: 'Material' ,align: 'center'},
                    { key: 'Costo_Unitario', title: 'Costo Unitario' ,align: 'center'},
                    { key: 'Cantidad', title: 'Cantidad' ,align: 'center'},
                    { key: 'total', title: 'Precio' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                items:[]  ,
                action: '',
                family: '',
                costo: 0,
                costo_personal: 0,
                costo_unit: 0
            },
            rules: [
                value => {
                    if (value) return true
                    return 'Este campo no puede ir vacio'
                },
            ],
    }},
    created(){
        this.getdatos()
        this.obtenermateriales()
    },
    methods: {
        async obtenermateriales(){

            await axios.get(url+"/Familia")
            .then((result) => {    
                this.product.tipos = result.data.filter(function(family){
                    return family.Codigo != "GN";
                })
                // this.materiales.datas = result.data
            })  

        },
        async getmateriales(){
            await axios.get(url+"/materials")
            .then((result) => {    
                console.log(result.data)
                this.materiales.datas = result.data
            })  
        },
        async guardarImages (){
            var imagen1 = document.getElementById('img1')
            var imagen2 = document.getElementById('img2')
            let formdata = new FormData();
            formdata.append('imagen1',imagen1.files[0]) 
            formdata.append('imagen2',imagen2.files[0]) 

            axios.post(url+'/producto/imgs/'+ this.product.id,formdata,{headers: {'Content-Type': 'application/x-www-form-urlencoded'}})
            .then((result) => {
                console.log(result.data)
                if(result.data == true){
                        this.dialog.loading= false
                        this.product.dialog = false
                        this.dialog.confirmacion=true                           
                        this.dialog.message="Imagenes cargadas correctamente"
                    }  else{
                        this.dialog.loading= false
                        this.product.dialog = false
                        this.dialog.error = true
                        this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    }
                    
            }).catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
        },
        async getdatos(){
            this.dialog.loading= true
            this.loadingTable = true
            axios.get(url+"/productos")  
            .then((result) => {    
                console.log(result.data)
                this.product.data = result.data
                this.dialog.loading= false
                this.loadingTable = false
            })  
            .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
        },
        async guardar(){
            this.dialog.loading= true
            let datas = {
                "Descripcion_corta": this.product.Descripcion_corta,
                "Descripcio_largan": this.product.Descripcio_largan,
                "Medidad_ext_large": this.product.Medidad_ext_large,
                "Medidad_ext_ancho": this.product.Medidad_ext_ancho,
                "Medidad_ext_alto": this.product.Medidad_ext_alto,  
                "Estructura": this.product.Estructura,
                "Forro_ext": this.product.Forro_ext,
                "Puertas": this.product.Puertas,
                "Piso": this.product.Piso,
                "Instalacion": this.product.Instalacion,
                "Rampa_hidraulica": this.product.Rampa_hidraulica,
                "Forro_int": this.product.Forro_int,
                "typo": this.product.typo
            }

            if(this.product.action=='update'){
                await axios.put(url+"/productos/"+this.product.id,datas)
                .then((result) => {    
                    if(result.data == true){
                            this.dialog.loading= false
                            this.product.dialogedit = false
                            this.getdatos()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Producto actualizado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.product.dialog = false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                        this.product.dialog = false
                        console.log(e);
                        this.dialog.loading= false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.product.dialogedit = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
            }else{
                await axios.post(url+"/productos",datas)
                .then((result) => {    
                        if(result.data == true){
                            this.dialog.loading= false
                            this.product.dialog = false
                            this.getdatos()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Producto creado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.product.dialog = false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                        console.log(e);
                        this.dialog.loading= false
                        this.product.dialog = false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
            }  
                
        },
        async guardarmateriales(){
            this.dialog.loading= true
            let datas ={ 
                "producto_id" :  this.product.id,
                "materiale_id": this.materiales.materiale_id,
                "Cantidad": this.materiales.cantidad,
                "Costo_unitario": this.materiales.costo == this.materiales.costo_unit ? null : this.materiales.costo
            }

            console.log(datas )

            if(this.materiales.action == 'update'){
                await axios.put(url+"/product/materiales/"+this.materiales.id,datas)
                .then((result) => {    
                    console.log(result.data)
                    if(result.data == true){
                        this.dialog.loading= false
                        this.getmatsprod()
                        this.materiales.dialog = false
                        this.dialog.confirmacion=true                           
                        this.dialog.message="Material actualizado correctamente"
                    }  else{
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.dialog.error = true
                        this.materiales.dialog = false
                        this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    }
                })   
                .catch((e) => {
                    console.log(e);
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.materiales.dialog = false
                    this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                });
            }else{
                await axios.post(url+"/product/materiales",datas)
                .then((result) => {    
                    console.log(result.data)
                    if(result.data == true){
                        this.dialog.loading= false
                        this.getmatsprod()
                        this.dialog.confirmacion=true                           
                        this.dialog.message="Material creado correctamente"
                    }  else{
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.dialog.error = true
                        this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    }
                })   
                .catch((e) => {
                    console.log(e);
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                });
            }                      
        },
        async deleteMaterial(){
            this.dialog.loading= true
            await axios.delete(url+"/product/materiales/"+this.materiales.id)
                .then((result) => {    
                    if(result.data == true){
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.getmatsprod()
                        this.materiales.dialog = false
                        this.dialog.confirmacion=true                           
                        this.dialog.message="Material eliminado correctamente"
                    }  else{
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.dialog.error = true
                        this.materiales.dialog = false
                        this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    }
                }) 
                .catch((e) => {
                    console.log(e);
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.materiales.dialog = false
                    this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                });
        },
        async deleteproduct(){
            this.dialog.loading= true
            this.loadingTable = true
            await axios.delete(url+"/productos/"+this.product.id)
                .then((result) => {    
                    if(result.data == true){
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.getdatos()
                        this.dialog.confirmacion=true                           
                        this.dialog.message="Producto eliminado correctamente"
                    }  else{
                        this.dialog.loading= false
                        this.dialog.warning= false
                        this.dialog.error = true
                        this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                    }
                }) 
                .catch((e) => {
                        this.dialog.warning= false
                        console.log(e);
                        this.dialog.loading= false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.product.dialogedit = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
        },
        async getmatsprod(){
            this.loadingTable = true
            await axios.get(url+"/productos/"+this.product.id)
                .then((result) => {    
                    console.log(result.data)
                    this.loadingTable = false
                    this.materiales.items = result.data
                }) 
                .catch((e) => {                        
                        console.log(e);
                        this.dialog.error = true
                        this.loadingTable = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
        },
        async update(item){
            await axios.get(url+"/materials")
            .then((result) => {    
                console.log(result.data)
                this.materiales.datas = result.data
                this.materiales.datas= result.data.filter(function(materiales){
                    return materiales.Familia == item.typo || materiales.Familia == "GN";
                })
            })  
 
   
            this.product.img1 = this.urlimages+item.Imagen1 == 0 ? "" :   this.urlimages+item.Imagen1
            this.product.img2 = this.urlimages+item.Imagen2 == 0 ? "" :   this.urlimages+item.Imagen2

            console.log(this.product.img1 )

            this.product.id = item.id
            this.product.Descripcion_corta = item.Descripcion_corta
            this.product.Descripcio_largan = item.Descripcio_largan  
            this.product.Medidad_ext_large = item.Medidad_ext_large
            this.product.Medidad_ext_ancho = item.Medidad_ext_ancho   
            this.product.Medidad_ext_alto = item.Medidad_ext_alto
            this.product.Estructura = item.Estructura
            this.product.Forro_ext = item.Forro_ext
            this.product.Puertas = item.Puertas
            this.product.Piso = item.Piso 
            this.product.Instalacion = item.Instalacion
            this.product.Rampa_hidraulic = item.Rampa_hidraulica
            this.product.Forro_int = item.Forro_int
            this.product.typo = item.typo
            this.product.dialogedit = true
            this.product.action='update'
            this.getmatsprod()
        },
        newproduct(){
            this.product.id = 0
            this.product.Descripcion_corta = ''
            this.product.Descripcio_largan = ''
            this.product.Medidad_ext_large =0
            this.product.Medidad_ext_ancho = 0
            this.product.Medidad_ext_alto  = 0            
            this.product.Estructura = ''
            this.product.Forro_ext = ''
            this.product.Puertas = ''
            this.product.Piso = ''
            this.product.Instalacion = ''
            this.product.Rampa_hidraulic = ''
            this.product.Forro_int = ''
            this.product.action='new'
        },
        updateMaterial(item){
            console.log(item)
            this.materiales.dialog=true
            this.materiales.family=item.Familia
            this.materiales.id=item.id
            this.materiales.cantidad=item.Cantidad
            this.materiales.action='update'; 
            item.Costo_personal == null ? this.materiales.costo = item.Costo_Unitario : this.materiales.costo =   item.Costo_personal
            this.materiales.costo_personal = item.Costo_personal
            this.materiales.costo_unit = item.Costo_Unitario
        },
       async newMaterial(){
            // console.log(item)
            // this.materiales.family=""
            // this.materiales.id=0
            // this.materiales.cantidad=item.Cantidad
            this.materiales.action='new';
            await this.guardarmateriales() 
        }
    }
}
</script>

<style>

</style>