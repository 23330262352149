<template>
    <v-row style="padding-left:5px; margin-top:20px; padding-right: 15px; ">
        Usuarios 
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->
        
        <v-tooltip text="Actualizar" location="bottom" >
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="deep-orange-accent-3" @click="obtener" ></v-btn>
            </template>
        </v-tooltip>
        <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3"  style="margin-left:5px; margin-bottom:20px "
        @click="usuarios.dialog = true; this.usuarios.action='new'; this.usuarios.id=0">Nuevo Usuario</v-btn>
    </v-row>

    <v-card>
    <template v-slot:text>
        <v-text-field
            height="20px"
            v-model="search"
            label="Search"
            single-line
            variant="outlined"
            hide-details
            density="compact"
            style="width: 45%;"
        ></v-text-field>
    </template>

        <v-data-table :loading="loadingTable"
        :headers="usuarios.headers"
        :items="usuarios.data"
        :search="search"
        density="compact"
        class="tabledts"
        >
        <template v-slot:[`item.name`]="{ item }">
            <v-btn  density="compact"
            @click="usuarios.dialog = true; updata(item); ">{{ item.name }}</v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
            <v-btn icon="mdi-delete" color="red"  density="compact"
            @click="dialog.warning = true; usuarios.id= item.id; dialog.message= 'Estas por eliminar '+item.name "></v-btn>
        </template>

        </v-data-table>

 
    </v-card>

    <v-dialog v-model="usuarios.dialog" persistent  width="1024" >
        <v-card>
        <v-card-title>
            <span class="text-h5">Usuarios </span>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent  >
                <v-row>
                    <v-col cols="12" sm="12"  md="12">
                        <v-text-field v-model="usuarios.Nombre"  :rules="rules"  label="Nombre*"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="6">
                        <v-text-field  v-model="usuarios.Usuario" :rules="rules"  label="Usuario*"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6"  md="6"  >
                        <v-text-field :type="usuarios.typeps" id="pass"
                        v-model="usuarios.Password"
                        label="Password"
                        :append-inner-icon="usuarios.typeps =='Password' ? 'mdi-eye-off-outline'  : 'mdi-eye-outline' "
                        @click:append-inner="toggleMarker"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="usuarios.dialog = false">
                            Cerrar
                        </v-btn>

                        
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="guardarcliente">
                            Guardar
                        </v-btn>
                        
                </v-card-actions>
            </v-form>
            <small>*Campos obligatorios</small>
        </v-card-text>
        
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.warning"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.warning = false "
                >Cancelar</v-btn>
                <v-btn   @click="deleteCLiente " >Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>




    <v-snackbar  v-model="dialog.confirmacion"
        :timeout="2000"
        color="deep-purple-accent-4"
        elevation="24"
        >

        <strong>{{ dialog.message }}</strong>
    </v-snackbar>


    <v-dialog v-model="dialog.error"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.error = false "
                >Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15" color="primary" indeterminate ></v-progress-circular></v-dialog>

</template>




<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
    data () {
        return {
            search: '',
            loadingTable:false,
            dialog: {
                loading:false,
                error: false,
                warning: false,
                confirmacion:false,
                message: '',
                type: ''
            },
            usuarios: {
                typeps: "password",
                dialog: false,
                headers: [
                    { key: 'name', title: 'Nombre' ,align: 'center'},
                    { key: 'email', title: 'Usuario' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: [],
                id:0,
                Nombre: "",
                Usuario: "",
                Password: "",
                action: ""
            },
            rules: [
                value => {
                    if (value) return true
                    return 'Este campo no puede ir vacio'
                },
            ],
    }},
    created(){
        this.obtener()
    },
    methods:{
        async obtener(){
            this.dialog.loading= true
            this.loadingTable = true
           await axios.get(url+"/Usuarios")
            .then((result) => {    
                console.log(result.data)
                this.usuarios.data = result.data
                this.loadingTable = false
                this.dialog.loading= false
            }) 
            .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
        },
        async guardarcliente(){
            this.dialog.loading= true
            let datas ={
                "name": this.usuarios.Nombre,
                "email": this.usuarios.Usuario,
                "password": this.usuarios.Password
            }


            if( this.usuarios.action == "update"){
                await axios.put(url+"/Usuarios/"+ this.usuarios.id,datas)
                    .then((result) => {    
                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.usuarios.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Usuario actualizado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
            }else{
                await axios.post(url+"/Usuarios",datas)
                    .then((result) => {    
                        // console.log(result.data)

                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.usuarios.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Usuario creado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                        console.log(e);
                        this.dialog.loading= false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.usuarios.dialog = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
            }
            
        },
        async deleteCLiente(){
            this.dialog.loading= true
            this.loadingTable = true
            await axios.delete(url+"/Usuarios/"+this.usuarios.id)
            .then((result) => {    
                console.log(result.data)
                
                if(result.data == true){
                    this.dialog.warning = false
                    this.loadingTable = false
                    this.dialog.loading= false
                    this.obtener()
                    this.dialog.confirmacion=true                           
                    this.dialog.message="Usuario elimnado correctamente"
                }  else{
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.dialog.warning = false
                    this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                }
            }) 
        },
        updata(item){
            this.usuarios.id= item.id
            this.usuarios.Nombre= item.name
            this.usuarios.Usuario= item.email
            this.usuarios.Password= item.password
            this.usuarios.action = "update"
        },
        toggleMarker () {
            this.usuarios.typeps == "Text" ? this.usuarios.typeps = "Password" :  this.usuarios.typeps = "Text"
        },
    }
}
</script>

<style>

</style>