<template>
    <v-row style="margin-left:3%; margin-top:20px; padding-right: 15px; width: 95%;">
        Cotizaciones 
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->
        
        <v-tooltip text="Actualizar" location="bottom" >
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="deep-orange-accent-3" @click="obtener" ></v-btn>
            </template>
        </v-tooltip>
        <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3"  style="margin-left:5px; margin-bottom:20px "
        @click="newcotization(); Cotizacion.dialog = true;">Nueva cotizacion</v-btn>
    </v-row>

    <v-card>
    <template v-slot:text>
        <v-text-field
            height="20px"
            v-model="search"
            label="Search"
            single-line
            variant="outlined"
            hide-details
            density="compact"
            style="width: 45%;"
        ></v-text-field>
    </template>

        <v-data-table :loading="loadingTable"
        :headers="Cotizacion.headers"
        :items="Cotizacion.data"
        :search="search"
        density="compact"
        class="tabledts"
        >
        <template v-slot:[`item.Num`]="{ item }">
            <v-btn  density="compact"
            @click="Cotizacion.dialog = true; updata(item); ">{{ item.Num }}</v-btn>
        </template>

        <template v-slot:[`item.cotization`]="{ item }">
            <v-btn icon="mdi-file-download" color="blue"  density="compact"
            @click="Cotizacion.id = item.id; downloadfile()"></v-btn>
            <v-btn icon="mdi-eye-outline" color="blue"  density="compact" style="margin-left: 10px;"
            @click="Cotizacion.id = item.id;  Cotizacion.pdf = true; console.log(item.id)  "></v-btn>
          
        </template>
        <template v-slot:[`item.delete`]="{ item }">
            <v-btn icon="mdi-delete" color="red"  density="compact"
            @click="dialog.warning = true; Cotizacion.id= item.id; dialog.message= 'Estas por eliminar la cotizacion '+item.Num "></v-btn>
        </template>

        </v-data-table>

 
    </v-card>

    <!-- <iframe v-if="Cotizacion.download = true" style="width: 100%; height: 100%; display: none;" src="https://cyrsistema.com/Api/public/index.php/cotization/pdf/down" frameborder="0"></iframe> -->
    
    <v-dialog v-model="Cotizacion.pdf" persistent  width="1024" >
    
        <v-card>
            <v-card-title>
                <span class="text-h5">Cotizacion </span>
            </v-card-title>
            <v-card-text style="width: 100%; height: 600px;">
                <iframe style="width: 100%; height: 100%;" :src="urldts+'/cotization/pdf/'+Cotizacion.id" frameborder="0"></iframe>
            </v-card-text>
            <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  color="blue-darken-1" variant="text"
                        @click="Cotizacion.pdf = false">
                        Cerrar
                    </v-btn>                      
                </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="Cotizacion.dialog" persistent  width="1024" >
        <v-card>
        <v-card-title>
            <span class="text-h5">Cotizacion </span>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent  >
                <v-row>
                    <v-col cols="12" sm="12"  md="4">
                        <v-text-field type="number" v-model="Cotizacion.Cotizacion"  :rules="rules"  label="No Cotizacion"></v-text-field>
                    </v-col>                    
                    <v-col cols="12" sm="12"  md="4">
                        <v-text-field type="date" v-model="Cotizacion.Fecha"  :rules="rules"  label="Fecha de emision"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="4">
                        <v-text-field type="number" v-model="Cotizacion.GananciaAdicional" label="Ganancia o adicional"></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="12"  md="9">
                        <v-autocomplete
                        label="clientes"                       
                        v-model="Cotizacion.Cliente"
                        :items="Cotizacion.Clientes"
                        item-title="Nombre"
                        item-value="id"
                        ></v-autocomplete>
                        <!-- <v-text-field
                        label="clientes"                       
                        v-model="Cotizacion.Cliente"
                        ></v-text-field> -->
                    </v-col>
                    <v-col cols="12" sm="12"  md="3">
                        <!-- <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3"  style="margin-left:5px; margin-bottom:20px "
                         @click="Cotizacion.dialog = true">Nuevo cliente</v-btn> -->
                    </v-col>
                    <v-col cols="12" sm="12"  md="9">
                        <v-autocomplete
                        label="Producto"                       
                        v-model="Cotizacion.Producto"
                        :items="Cotizacion.Productos"
                        item-title="Descripcion_corta"
                        item-value="id"
                        ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" sm="12"  md="3">
                        <v-text-field type="number" v-model="Cotizacion.cantprod"  label="Cantidad"></v-text-field>
                    </v-col>

                    
                </v-row>

                <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="Cotizacion.dialog = false">
                            Cerrar
                        </v-btn>

                        
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="guardarcliente">
                            Guardar
                        </v-btn>
                        
                </v-card-actions>
            </v-form>
            <small>*Campos obligatorios</small>
        </v-card-text>
        
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.warning"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.warning = false "
                >Cancelar</v-btn>
                <v-btn   @click="deleteCotizacion" >Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>




    <v-snackbar  v-model="dialog.confirmacion"
        :timeout="2000"
        color="deep-purple-accent-4"
        elevation="24"
        >

        <strong>{{ dialog.message }}</strong>
    </v-snackbar>


    <v-dialog v-model="dialog.error"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.error = false "
                >Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15" color="primary" indeterminate ></v-progress-circular></v-dialog>

    <div v-show="false" id="donwlds">
</div>
</template>




<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
    data () {
        return {
            urldts: url,
            search: '',
            loadingTable:false,
            dialog: {
                loading:false,
                error: false,
                warning: false,
                confirmacion:false,
                message: '',
                type: ''
            },
            Cotizacion: {
                pdf:false,
                download: false,
                dialog: false,
                headers: [
                    { key: 'Num', title: 'No Cotizacion' ,align: 'center'},
                    { key: 'Nombre', title: 'Cliente' ,align: 'center'},
                    { key: 'Date', title: 'Fecha' ,align: 'center'},
                    { key: 'Descripcion_corta', title: 'Producto' ,align: 'center'},
                    { key: 'cotization', title: 'Cotizacion' ,align: 'center',sortable: false,},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: [],
                id:0,
                Clientes:[],
                Productos: [],
                Cotizacion: "",
                Cliente: "",
                Producto: "",
                Fecha:  "",
                GananciaAdicional: 0,
                cantprod: 1
            },
            rules: [
                value => {
                    if (value) return true
                    return 'Este campo no puede ir vacio'
                },
            ],
    }},
    created(){
        const date = new Date()
        this.Cotizacion.Fecha =date.getFullYear()+'-'+ date.getMonth()+1+'-'+date.getDate() 
        console.log(this.Cotizacion.Fecha )
        this.obtener()
        this.catalogos()
    },
    methods:{
        async obtener(){
            this.dialog.loading= true
            this.loadingTable = true
           await axios.get(url+"/cotization")
            .then((result) => {  
                console.log(result.data) 
                this.Cotizacion.data = result.data
                this.loadingTable = false
                this.dialog.loading= false
            }) 
            .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
        },
        async guardarcliente(){
            this.dialog.loading= true
            let datas ={
                "Num": this.Cotizacion.Cotizacion,
                "producto_id": this.Cotizacion.Producto,
                "cliente_id": this.Cotizacion.Cliente,
                "Date": this.Cotizacion.Fecha,
                "GananciaAdicional": this.Cotizacion.GananciaAdicional,
                "cantprod": this.Cotizacion.cantprod
            }
            if( this.Cotizacion.action == "update"){
                await axios.put(url+"/cotization/"+ this.Cotizacion.id,datas)
                    .then((result) => {    
                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.Cotizacion.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Cliente actualizado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
            }else{
                await axios.post(url+"/cotization",datas)
                    .then((result) => {    
                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.Cotizacion.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Cotizacion creada correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                        console.log(e);
                        this.dialog.loading= false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.Cotizacion.dialog = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
            }
            
        },
        async deleteCotizacion(){
            this.dialog.loading= true
            this.loadingTable = true
            await axios.delete(url+"/cotization/"+this.Cotizacion.id)
            .then((result) => {    
                console.log(result.data)
                
                if(result.data == true){
                    this.dialog.warning = false
                    this.loadingTable = false
                    this.dialog.loading= false
                    this.obtener()
                    this.dialog.confirmacion=true                           
                    this.dialog.message="Cotizacion elimnada correctamente"
                }  else{
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.dialog.warning = false
                    this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                }
            }) 
        },
        updata(item){
            this.Cotizacion.id = item.id 
            this.Cotizacion.Cotizacion = item.Num
            this.Cotizacion.Cliente = item.cliente_id
            this.Cotizacion.Producto = item.producto_id
            this.Cotizacion.Fecha = item.Date
            this.Cotizacion.GananciaAdicional = item.GananciaAdicional
            this.Cotizacion.cantprod = item.cantprod
            this.Cotizacion.action = "update"
        },
        newcotization(){
            const date = new Date()
            this.Cotizacion.id = ""
            this.Cotizacion.Cotizacion = ""
            this.Cotizacion.Cliente = ""
            this.Cotizacion.Producto = ""
            this.Cotizacion.Fecha =date.getFullYear()+'-'+ date.getMonth()+1+'-'+date.getDate() 
            this.Cotizacion.GananciaAdicional = ""
            this.Cotizacion.cantprod =  1
            this.Cotizacion.action = "new"
        },
        downloadfile(){
            var ur = url+"/cotization/pdf/down/"+this.Cotizacion.id
            var iframe = document.createElement('iframe')
            iframe.setAttribute("src", ur)
            document.getElementById("donwlds").appendChild(iframe)
        },


        async catalogos(){
            await axios.get(url+"/Clientes")
            .then((result) => {    
                this.Cotizacion.Clientes = result.data
            }) 

            axios.get(url+"/productos")
            .then((result) => {    
                console.log(result.data)
                this.Cotizacion.Productos = result.data
            }) 

        }
    }
}
</script>

<style>

</style>