<template>
    <v-row style="padding-left:5px; margin-top:20px; padding-right: 15px; ">
        Clientes 
        <v-spacer></v-spacer>
        <!-- <v-btn icon="mdi-reload" variant="outlined"></v-btn> -->
        
        <v-tooltip text="Actualizar" location="bottom" >
            <template v-slot:activator="{ props }">
                <v-btn v-bind="props" prepend-icon="mdi-reload" color="deep-orange-accent-3" @click="obtener" ></v-btn>
            </template>
        </v-tooltip>
        <v-btn prepend-icon="mdi-plus" color="deep-orange-accent-3"  style="margin-left:5px; margin-bottom:20px "
        @click="clientes.dialog = true">Nuevo cliente</v-btn>
    </v-row>

    <v-card>
    <template v-slot:text>
        <v-text-field
            height="20px"
            v-model="search"
            label="Search"
            single-line
            variant="outlined"
            hide-details
            density="compact"
            style="width: 45%;"
        ></v-text-field>
    </template>

        <v-data-table :loading="loadingTable"
        :headers="clientes.headers"
        :items="clientes.data"
        :search="search"
        density="compact"
        class="tabledts"
        >
        <template v-slot:[`item.Nombre`]="{ item }">
            <v-btn  density="compact"
            @click="clientes.dialog = true; updata(item); ">{{ item.Nombre }}</v-btn>
        </template>

        <template v-slot:[`item.delete`]="{ item }">
            <v-btn icon="mdi-delete" color="red"  density="compact"
            @click="dialog.warning = true; clientes.id= item.id; dialog.message= 'Estas por eliminar '+item.Nombre "></v-btn>
        </template>

        </v-data-table>

 
    </v-card>

    <v-dialog v-model="clientes.dialog" persistent  width="1024" >
        <v-card>
        <v-card-title>
            <span class="text-h5">Clientes </span>
        </v-card-title>
        <v-card-text>
            <v-form @submit.prevent  >
                <v-row>
                    <v-col cols="12" sm="12"  md="12">
                        <v-text-field v-model="clientes.Nombre"  :rules="rules"  label="Nombre"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="6">
                        <v-text-field  v-model="clientes.Contacto" :rules="rules"  label="Contacto"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="6">
                        <v-text-field v-model="clientes.Email"   :rules="rules"  label="Email"></v-text-field>
                    </v-col>

                    Direccion
                    <v-divider></v-divider>
                    <v-col cols="12" sm="12"  md="3">
                        <v-text-field v-model="clientes.Calle"  :rules="rules"  label="Calle"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="2">
                        <v-text-field  v-model="clientes.CP" :rules="rules"  label="CP"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="3">
                        <v-text-field  v-model="clientes.Municipio" :rules="rules"  label="Municipio"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12"  md="4">
                        <v-text-field  v-model="clientes.Estado" :rules="rules"  label="Estado"></v-text-field>
                    </v-col>
                    
                    <!-- <iframe src="https://google.com/maps/@19.5717691,-99.4321754,11z?entry=ttu" frameborder="0"></iframe> -->
                </v-row>

                <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="clientes.dialog = false">
                            Cerrar
                        </v-btn>

                        
                        <v-btn  color="blue-darken-1" variant="text"
                            @click="guardarcliente">
                            Guardar
                        </v-btn>
                        
                </v-card-actions>
            </v-form>
            <small>*Campos obligatorios</small>
        </v-card-text>
        
        </v-card>
    </v-dialog>

    <v-dialog v-model="dialog.warning"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="orange-darken-3" title="Accion requiere confirmacion"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.warning = false "
                >Cancelar</v-btn>
                <v-btn   @click="deleteCLiente " >Confirmar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>




    <v-snackbar  v-model="dialog.confirmacion"
        :timeout="2000"
        color="deep-purple-accent-4"
        elevation="24"
        >

        <strong>{{ dialog.message }}</strong>
    </v-snackbar>


    <v-dialog v-model="dialog.error"
        transition="dialog-bottom-transition"
        width="auto" >
        <v-card>
            <v-toolbar color="red" title="Ups, ocurrio un error"></v-toolbar>
                <v-card-text>
                <div class="text-h6 pa-12">{{dialog.message}}</div>
                </v-card-text>
                <v-card-actions class="justify-end">
                <v-btn
                    variant="text"
                    @click="dialog.error = false "
                >Cerrar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>


    <v-dialog v-model="dialog.loading" fullscreen> <v-progress-circular style="margin: auto;" :size="200" :width="15" color="primary" indeterminate ></v-progress-circular></v-dialog>

</template>




<script>
import axios from "axios";
const url = process.env.VUE_APP_SERVICE_URL; 
export default {
    data () {
        return {
         
            search: '',
            loadingTable:false,
            dialog: {
                loading:false,
                error: false,
                warning: false,
                confirmacion:false,
                message: '',
                type: ''
            },
            clientes: {
                dialog: false,
                headers: [
                    { key: 'Nombre', title: 'Cliente' ,align: 'center'},
                    { key: 'Contacto', title: 'Telefono' ,align: 'center'},
                    { key: 'Email', title: 'Email' ,align: 'center'},
                    { key: 'adrress', title: 'Direccion' ,align: 'center'},
                    { key: 'delete', title: 'Eliminar' ,align: 'center',sortable: false,},
                ],
                data: [],
                id:0,
                Nombre: "",
                Contacto: "",
                Email: "",
                Calle: "",
                CP: "",
                Municipio: "",
                Estado: "",
                action: ""
            },
            rules: [
                value => {
                    if (value) return true
                    return 'Este campo no puede ir vacio'
                },
            ],
    }},
    created(){
        this.obtener()
    },
    methods:{
        async obtener(){
            this.dialog.loading= true
            this.loadingTable = true
           await axios.get(url+"/Clientes")
            .then((result) => {    
                this.clientes.data = result.data
                this.loadingTable = false
                this.dialog.loading= false
            }) 
            .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
        },
        async guardarcliente(){
            this.dialog.loading= true
            let datas ={
                "Nombre": this.clientes.Nombre,
                "Contacto": this.clientes.Contacto,
                "Email": this.clientes.Email,
                "Calle": this.clientes.Calle,
                "CP": this.clientes.CP,
                "Municipio": this.clientes.Municipio,
                "Estado": this.clientes.Estado
            }
            if( this.clientes.action == "update"){
                await axios.put(url+"/Clientes/"+ this.clientes.id,datas)
                    .then((result) => {    
                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.clientes.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Cliente actualizado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                console.log(e);
                this.dialog.loading= false
                this.dialog.error = true
                this.loadingTable = false
                this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
            });
            }else{
                await axios.post(url+"/Clientes",datas)
                    .then((result) => {    
                        this.loadingTable = false
                        if(result.data == true){
                            this.dialog.loading= false
                            this.clientes.dialog = false
                            this.obtener()
                            this.dialog.confirmacion=true                           
                            this.dialog.message="Cliente creado correctamente"
                        }  else{
                            this.dialog.loading= false
                            this.dialog.error = true
                            this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                        }
                    }) 
                    .catch((e) => {
                        console.log(e);
                        this.dialog.loading= false
                        this.dialog.error = true
                        this.loadingTable = false
                        this.clientes.dialog = false
                        this.dialog.message ="Ocurrio un error, intente de nuevo mas tarde"
                    });
            }
            
        },
        async deleteCLiente(){
            this.dialog.loading= true
            this.loadingTable = true
            await axios.delete(url+"/Clientes/"+this.clientes.id)
            .then((result) => {    
                console.log(result.data)
                
                if(result.data == true){
                    this.dialog.warning = false
                    this.loadingTable = false
                    this.dialog.loading= false
                    this.obtener()
                    this.dialog.confirmacion=true                           
                    this.dialog.message="Cliente elimnado correctamente"
                }  else{
                    this.dialog.loading= false
                    this.dialog.error = true
                    this.dialog.warning = false
                    this.dialog.message ="No pudo ser procesada la solicitud verifique los datos e intente de nuevo"
                }
            }) 
        },
        updata(item){
            this.clientes.id= item.id
            this.clientes.Nombre= item.Nombre
            this.clientes.Contacto= item.Contacto
            this.clientes.Email= item.Email
            this.clientes.Calle= item.Calle
            this.clientes.CP= item.CP
            this.clientes.Municipio= item.Municipio
            this.clientes.Estado= item.Estado
            this.clientes.action = "update"
        }
    }
}
</script>

<style>

</style>