<template>


<v-toolbar dark prominent color="black" density="compact">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <v-avatar size="80px" >
            <v-img
            alt="Avatar"
            src="../assets/logo.png"
            ></v-img>
        </v-avatar>
        <v-spacer></v-spacer>

       
        <v-btn @click="menuUser = !menuUser">
            {{ user }}
            <v-icon >mdi-menu-down</v-icon>
        </v-btn>
    </v-toolbar>



    <!-- <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
      >
        <v-list-item style="height: 65px;"
          prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
          title="John Leider"
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
            <v-tooltip text="Cotizaciones">
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" prepend-icon="mdi-file-document-multiple-outline" title="Cotizaciones" @click="openContent('cotization-page')"></v-list-item>
                </template>
            </v-tooltip>

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" prepend-icon="mdi-truck-trailer" title="Productos"  @click="openContent('product-page')"></v-list-item> 
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Productos</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de productos para <br> agregar, editar y eliminar
                        </v-card-text>  
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>


            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" prepend-icon="mdi-grain" title="Materiales"  @click="openContent('materiales-page')"></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Materiales</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de materiales para <br> agregar, editar y eliminar
                        </v-card-text>  
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" prepend-icon="mdi-account-group" title="Clientes"  @click="openContent('clientes-page')"></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Clientes</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de clientes para <br> agregar, editar y eliminar
                        </v-card-text>                       
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>

            <v-tooltip >
                <template v-slot:activator="{ props }">
                    <v-list-item v-bind="props" prepend-icon="mdi-account-circle" title="Usuarios"  @click="openContent('clientes-page')"></v-list-item>
                </template>
                <v-tooltip-text>
                    <v-card  theme="dark" style="background: transparent;">
                        <v-card-title style="font-size:medium;">Usuarios</v-card-title>
                        <v-card-text style="font-size: small;">
                            Gestion de usuarios para <br> agregar, editar y eliminar
                        </v-card-text>  
                    </v-card>
                </v-tooltip-text>
            </v-tooltip>


            
            
        </v-list>
      </v-navigation-drawer>
    </v-layout> -->

    
    <v-card min-width="300" width="300" v-if="menuUser"  style="position: absolute;  right: 10px; z-index: 1;">
        <v-list>
            <v-list-item
                prepend-avatar="../assets/logo.png"
                :title="user"
            >
            </v-list-item>
        </v-list>
        <v-divider></v-divider>

        <v-list>
            <v-list-item @click="logOut">
            Cerrar sesion
            </v-list-item>
        </v-list>

    </v-card>



    <v-layout>
        <v-navigation-drawer color="indigo-darken-4" style="min-width: 3%;"
        v-model="drawer" 
        temporary>
        <v-list-item style="height: 65px;" nav  prependIcon="mdi-apps" variant="tonal" @click="drawer=!drawer">
            <v-img width="30%"
            src="../assets/logo.png"
            ></v-img>
        </v-list-item>

        <v-divider></v-divider>

            <v-list density="compact" nav>
                <v-list-item prepend-icon="mdi-file-document-multiple-outline" title="Cotizaciones" @click="openContent('cotization-page')"></v-list-item>
                <v-list-item prepend-icon="mdi-truck-trailer" title="Productos"  @click="openContent('product-page')"></v-list-item> 
                <v-list-item prepend-icon="mdi-grain" title="Materiales"  @click="openContent('materiales-page')"></v-list-item>
                <v-list-item prepend-icon="mdi-account-group" title="Clientes"  @click="openContent('clientes-page')"></v-list-item>
                <v-list-item prepend-icon="mdi-account-circle" title="Usuarios"  @click="openContent('users-page')"></v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-layout> 




</template>

<script>
// import axios from "axios";
// const url = process.env.VUE_APP_SERVICE_URL
export default {
    data(){
        return{
            menuUser: false,
            user: 'usuario',
            section: 'hfhjfgh',
            userdta:[],
            drawer: false,
            rail: true,
            }
    },
    created(){
        this.user = window.localStorage.getItem('user');
    },
    methods:{
        async logOut(){            
            localStorage.clear()
            window.location.reload()
        },
        openContent(page){
            this.mosSubmen = false
            this.menutal = false 
            this.menuActiv = page
            this.$emit('menuactiv', page)
        },
    }
}

</script>